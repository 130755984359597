import Axios from 'axios';
import moment from 'moment';

  export default {
    data() {
      return {
        tipo: 'admin',
        hirings: [],
        chart: {
          height: 150,
          data: {
            labels: ["JAN", "MAR", "MAI", "JUL", "SET", "NOV"],
            datasets: [
              {
                label: 'Novos projetos',
                backgroundColor: '#7553E2',
                data: [Math.random(), Math.random(), Math.random(), Math.random(),Math.random(), Math.random()]
              }, {
                label: 'Projetos antigos',
                backgroundColor: '#74E7D0',
                data: [Math.random(), Math.random(), Math.random(), Math.random(),Math.random(), Math.random()]
              }
            ]
          },
          options: {
            responsive: true,
          },
        },
        filter: {
          month: '',
          year: '',
        },
        all_files: {},
        files: {},
        show: 10,
        page: 0,
        perPage: 9,
        resultCount: 0,
        hirings_squads: {}
      }
    },
    mounted() {
      let me = this.$store.state.me;
      let company = this.$store.state.company;
      const TOKEN = this.$store.state.token;
      if (this.$store.state.role == 'admin') this.$router.push({ path: '/dashboard/admin' });
      if (this.$store.state.role == 'professional') this.$router.push({ path: '/dashboard/professional' });
      if (company) {
        if (!company.community_flag) this.$router.push({ path: '/dashboard/client/mycommunity-notamember' });
      } else {
        axios
        .get('/api/v1/companies/' + me.company_id, {
          headers: {
            Authorization: 'Bearer ' + TOKEN,
          }
        })
        .then(response => {
          if (!response.data.error) {
            company = response.data.data;
            if (!company.community_flag) this.$router.push({ path: '/dashboard/client/mycommunity-notamember' });
          } else {
            console.log(response.data.error);
          }
        });
      }

      axios
        .get('/api/v1/hirings?company=' + me.company_id + '&all', {
          headers: {
            Authorization: 'Bearer ' + TOKEN,
          }
        })
        .then(response => {
          this.hirings = (response.data) ? response.data.data : [];
      });

      axios
      .get('/api/v1/squads?company_id=' + me.company_id, {
        headers: {
          Authorization: 'Bearer ' + TOKEN,
        }
      })
      .then(response => {
        this.hirings_squads = response.data
      });

      this.getFilesUser(me, TOKEN)
    },

    computed: {

      active() {
        // status 1, 2, 3, ou 4
        // aberto, selecão, ativo mas não cancelado ou encerrado
        return this.hirings.filter(hiring => {
          return hiring.status != 5 && !hiring.squad_id;
        });
      },

      totalPages() {
        console.log(Math.ceil(this.resultCount / this.perPage) + " totalPages");
        return Math.ceil(this.resultCount / this.perPage);
      },

    },

    methods: {

      sumApprovedValues(hirings) {
        var sum = 0;
        hirings.forEach((hiring, index) => {
          sum += hiring.approved_value;
        });
        return sum;
      },

      toCurrency(val) {
        return (val/100).toLocaleString("pt-BR", {style: "currency", currency: "BRL", minimumFractionDigits: 2});
      },

      nextPage() {
        this.page++;
      },

      prevPage() {
        this.page--;
      },

      paginate(list) {
        this.resultCount = list.length;
        if (this.page >= this.totalPages) {
          this.page = Math.max(0, this.totalPages - 1);
        }
        var index = this.page * this.perPage;
        return list.slice(index, index + this.perPage);
      },

      formatDate(date) {
        return moment(date).format('DD/MM/YYYY');
      },

       getFilesUser(me, TOKEN){
         axios .get('/api/v1/users/' + me.id + '/get-files-user', {
          headers: {
            Authorization: 'Bearer ' + TOKEN,
          }
        }).then(response => {
            this.all_files = response.data.data
            this.files = response.data.data.slice(0,10)
        });
       
      },

      seeMore(x){
        this.show = this.show + x
        this.files = this.all_files.slice(0,this.show)

        if(this.files.length == this.all_files.length){
          this.all_files = 10
        }
      },
    },


  }
